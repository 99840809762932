import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import "./charts.css";

const chartData = {
  labels: ["Estado de Acreditación"],
  datasets: [
    {
      label: "Acreditados",
      data: [200], // Suponiendo que hay 200 programas acreditados
      backgroundColor: "#3d3936",
    },
    {
      label: "No Acreditados",
      data: [50], // Suponiendo que hay 50 programas no acreditados
      backgroundColor: "#e2211c",
    },
  ],
  cHeader: "Total de Programas Universitarios Acreditados",
  cTitle: "Programas Acreditados vs No Acreditados",
  cText: "Comparativa del estado de acreditación de los programas universitarios.",
};

const options = {
  indexAxis: 'y', // Añadimos esta línea para cambiar la orientación del gráfico a horizontal
  responsive: true,
  interaction: {
    mode: 'point',
    intersect: true,
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      display: true,
      position: "top",
      align: "center"
    },
    tooltip: {
      enabled: true,
    }
  },
  scales: {
    x: {
      beginAtZero: true, // Para gráficos de barras horizontales, este será el eje de los valores
      grid: {
        display: false
      }
    },
    y: { // Este será el eje de las categorías para un gráfico de barras horizontal
      grid: {
        display: false
      }
    }
  }
}

// Gráfico de barra horizontal
function IndicadorHorizontalBarChart() {
  return (
    <Card className={"chart-back"}>
      <Card.Body>
        <Card.Title className={"card-text"}>{chartData.cHeader}</Card.Title>
        <div  style={{ width: '50%', height: '300px', margin: '0 auto' }}>
          <Bar data={chartData} options={options} />
        </div>
        <Card.Text className={"card-text"}>{chartData.cText}</Card.Text>
      </Card.Body>
    </Card>  
  );
}

export default IndicadorHorizontalBarChart;
