import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import "./charts.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const chartData = {
  labels: ['Santiago', 'Temuco', 'Talca', 'Concepción', 'Valparaíso', 'Antofagasta', 'Iquique', 'La Serena', 'Rancagua'],
  datasets: [
    {
      label: 'Programas Acreditados',
      data: [120, 90, 60, 110, 80, 70, 50, 100, 65], // Cantidad de programas acreditados
      backgroundColor: [
        "rgba(61, 57, 54, 0.2)",  
        "rgba(226, 33, 28, 0.2)", 
        "rgba(76, 175, 80, 0.2)", 
        "rgba(255, 159, 243, 0.2)",
        "rgba(64, 224, 208, 0.2)", 
        "rgba(153, 102, 255, 0.2)",
        "rgba(54, 162, 235, 0.2)", 
        "rgba(255, 159, 64, 0.2)", 
        "rgba(255, 215, 0, 0.2)", 
      ],
      borderColor: [
        '#3d3936', // Gris oscuro
        '#e2211c', // Rojo
        '#4CAF50', // Verde
        '#FF9FF3', // Rosa pastel
        '#40E0D0', // Turquesa claro
        '#9966FF', // Púrpura
        '#36A2EB', // Azul claro
        '#FF9F40', // Naranja claro
        '#FFD700', // Dorado
      ],
      borderWidth: 3,
    }
  ],
};




const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      enabled: true,
    }
  }
};

function AcreditadosPorSedeChart() {
  return (
    <Card className="chart-back">
      <Card.Body>
        <Card.Title className="card-text">Programas Acreditados por Sede</Card.Title>
        <div style={{ height: '300px' }}>
          <Doughnut data={chartData} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
}

export default AcreditadosPorSedeChart;
