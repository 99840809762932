
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const CustomApexChart = ( chartData ) => {
  // Configuración del gráfico
  const options = {
    series: chartData.series,
    chart: {
      height: 350,
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ['#00E396'],
    dataLabels: {
      enabled: true,
      formatter: function(val, opt) {
        const goals = opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex].goals;
  
        if (goals && goals.length) {
          return `${val} / ${goals[0].value}`;
        }
        return val;
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ['Actual', 'Expected'],
      markers: {
        fillColors: ['#00E396', '#775DD0'],
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={chartData.series} type="bar" height={350} />
    </div>
  );
};

export default CustomApexChart;