import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import "./charts.css";

const chartData = {
  labels: ["Estado de Acreditación"],
  datasets: [
    {
      label: "Acreditados",
      data: [200], // Suponiendo que hay 200 programas acreditados
      backgroundColor: "rgba(61, 57, 54, 0.2)", // Gris oscuro translúcido
      borderColor: "#3d3936", // Gris oscuro
      borderWidth: 3
    },
    {
      label: "No Acreditados",
      data: [50], // Suponiendo que hay 50 programas no acreditados
      backgroundColor: "rgba(226, 33, 28, 0.2)", // Rojo translúcido
      borderColor: "#e2211c", // Rojo
      borderWidth: 3
    },
    {
      label: "En Proceso",
      data: [90], // Suponiendo que hay 90 programas en proceso de acreditación
      backgroundColor: "rgba(76, 175, 80, 0.2)", // Verde
      borderColor: "#4CAF50", // Verde
      borderWidth: 3
    },
    {
      label: "Rechazados",
      data: [20], // Suponiendo que hay 20 programas rechazados
      backgroundColor: "rgba(255, 159, 243, 0.2)", // Rosa fuerte translúcido
      borderColor: "#FF9FF3", // Rosa pastel
      borderWidth: 3
    },
    {
      label: "Suspendidos",
      data: [40], // Suponiendo que hay 40 programas suspendidos
      backgroundColor: "rgba(64, 224, 208, 0.2)", // Turquesa claro
      borderColor: '#40E0D0', // Turquesa claro 
      borderWidth: 3
    },
    {
      label: "En Revisión",
      data: [15], // Suponiendo que hay 15 programas en revisión
      backgroundColor: "rgba(153, 102, 255, 0.2)", // Púrpura translúcido
      borderColor: "#9966FF", // Púrpura
      borderWidth: 3
    },
    {
      label: "Pendientes",
      data: [70], // Suponiendo que hay 70 programas pendientes de acreditación
      backgroundColor: "rgba(54, 162, 235, 0.2)", // Azul claro translúcido
      borderColor: "#36A2EB", // Azul claro
      borderWidth: 3
    },
    {
      label: "Finalizados",
      data: [120], // Suponiendo que hay 120 programas finalizados
      backgroundColor: "rgba(255, 159, 64, 0.2)", // Naranja claro translúcido
      borderColor: "#FF9F40", // Naranja claro
      borderWidth: 3
    },
    {
      label: "Aprobados",
      data: [180], // Suponiendo que hay 180 programas aprobados
      backgroundColor: "rgba(255, 215, 0, 0.2)", // Dorado translúcido
      borderColor: "#FFD700", // Dorado
      borderWidth: 3
    },
  ],
  cHeader: "Total de Programas Universitarios Acreditados",
  cTitle: "Programas Acreditados vs No Acreditados",
  cText: "Comparativa del estado de acreditación de los programas universitarios.", //segundo cambio desde actualizacion-card
};



const options = {
  responsive: true,
  interaction: {
    mode: 'point', // Cambiado a 'point' para tooltip de elemento individual
    intersect: true, // Cambiado a 'true' para solo mostrar cuando el cursor intersecta con el elemento
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      display: true,
      position: "top",
      align: "center"
    },
    tooltip: {
      enabled: true, // Asegúrate de que tooltips esté habilitado
    }
  },
  scales: {
    x: {
      grid: {
        display: false // Oculta las líneas de la cuadrícula en el eje X si lo deseas
      }
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true // Puedes ocultar esto también si quieres un gráfico más limpio
      }
    }
  }
}

// Gráfico de barra
function IndicadorBarChart() {
  return (
    <Card className={"chart-back"}>
      <Card.Body>
        <Card.Title className={"card-text"}>{chartData.cHeader}</Card.Title>
        <div  style={{ width: '50%', height: '300px', margin: '0 auto' }}>
          <Bar data={chartData} options={options} />
        </div>
        <Card.Text className={"card-text"}>{chartData.cText}</Card.Text>
      </Card.Body>
    </Card>  
  );
}

export default IndicadorBarChart;
