import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DocumentacionChart = ( chartData ) => {
  // Configuración del gráfico
  const options = {
    labels: chartData.labels,
    chart: {
      type: chartData.typeChart,
      height: 350,
      toolbar: {
        show: true,
        autoSelected: 'zoom' 
      },
    },
    dataLabels: {
        enabled: false // Esto desactivará las etiquetas de los datos en el gráfico
    },
    stroke: {
      colors: ['#fff']
    },
    yaxis: {
        show: false
    },
    fill: {
      opacity: 0.8
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    colors: ['#00E396', '#008FFB', '#FEB019', '#FF4560'], // Colores para diferenciar cada sector
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={chartData.series} type={chartData.typeChart} height={350} />
    </div>
  );
};

export default DocumentacionChart;
