import React from "react";
import { Line } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import "./charts.css";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const chartData = {
  labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio"],
  datasets: [
    {
      label: "Acreditados",
      data: [65, 59, 80, 81, 56, 75, 60], // Valores variados
      borderColor: "#3d3936", // Gris oscuro
      backgroundColor: "rgba(61, 57, 54, 0.2)", // Gris oscuro translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "No Acreditados",
      data: [28, 48, 42, 19, 86, 57, 78], // Valores variados
      borderColor: "#e2211c", // Rojo
      backgroundColor: "rgba(226, 33, 28, 0.2)", // Rojo translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "En Proceso",
      data: [45, 55, 60, 47, 70, 85, 73], // Valores variados
      borderColor: "#4CAF50", // Verde
      backgroundColor: "rgba(76, 175, 80, 0.2)", // Verde translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "Rechazados",
      data: [18, 35, 22, 55, 30, 65, 50], // Valores variados
      borderColor: "#FF9FF3", // Rosa pastel
      backgroundColor: "rgba(255, 159, 243, 0.2)", // Rosa pastel translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "Suspendidos",
      data: [20, 30, 40, 25, 45, 35, 65], // Valores variados
      borderColor: "#00CCCC", // Turquesa claro
      backgroundColor: "rgba(0, 204, 204, 0.2)", // Turquesa claro translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "En Revisión",
      data: [38, 45, 58, 70, 55, 62, 77], // Valores variados
      borderColor: "#9966FF", // Púrpura
      backgroundColor: "rgba(153, 102, 255, 0.2)", // Púrpura translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "Pendientes",
      data: [24, 36, 52, 65, 42, 55, 62], // Valores variados
      borderColor: "#36A2EB", // Azul claro
      backgroundColor: "rgba(54, 162, 235, 0.2)", // Azul claro translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "Finalizados",
      data: [30, 40, 60, 50, 75, 55, 68], // Valores variados
      borderColor: "#FF9F40", // Naranja claro
      backgroundColor: "rgba(255, 159, 64, 0.2)", // Naranja claro translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    },
    {
      label: "Aprobados",
      data: [55, 65, 80, 70, 90, 78, 88], // Valores variados
      borderColor: "#FFD700", // Dorado
      backgroundColor: "rgba(255, 215, 0, 0.2)", // Dorado translúcido
      fill: false,
      tension: 0.1,
      pointRadius: 5,
      pointHoverRadius: 5
    }
  ],
};




const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
    datalabels: {
        display: false,
      },
  },
};

// Gráfico de línea con estilo de puntos
function MultiAxisLineChart() {
  return (
    <Card className={"chart-back"}>
      <Card.Body>
        <Card.Title className={"card-text"}>Evolución de Programas Acreditados y No Acreditados</Card.Title>
        <div style={{ width: '50%', height: '300px', margin: '0 auto' }}>
          <Line data={chartData} options={options} />
        </div>
      </Card.Body>
    </Card>  
  );
}

export default MultiAxisLineChart;


