// StackedBarChart.js
import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import "./charts.css";
import { stackedbarOptions } from "./ChartOptions";

function StackedBarChart({ data }) {
  // Guardar copia de las etiquetas originales para los tooltips
  const originalLabels = [...data.labels];

  // Preparar las opciones con las etiquetas originales
  const options = stackedbarOptions(originalLabels);

  return (
    <Card className="chart-back">
      <Card.Body>
        <Card.Title className="card-text">{data.cHeader}</Card.Title>
        {data.cMetadata && 
          <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
            {data.cMetadata}
          </div>
        }
        <div style={{ height: '300px' }}>
          <Bar data={data} options={options} />
        </div>
        <Card.Text className="card-text">{data.cText}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default StackedBarChart;
