import BarChart from "../charts/BarChart";
import LineChart from "../charts/LineChart";
import PieChart from "../charts/PieChart";
import Page from "../Page";
import Title1 from "../Title1";
import Title2 from "../Title2";
import RowCharts from "../RowCharts";
import RadarChart from "../charts/RadarChart";
import BubbleChart from "../charts/BubbleChart";
import ComplexApexChartComponent from "../charts/ApexChart";
import ApexChartExample from "../charts/ApexChart2";
import DocumentacionChart from "../charts/DocumentacionChart";
import DoughnutChart from "../charts/DoughnutChart";
import StackedBarChart from "../charts/StackedBarChart";
//Elemento dinamico
//  Va desde una pagina hasta los graficos que la componen


const DinamicElement = (props) => {
    if (props.dinamicContent.elementType===undefined)
        return ""

    switch (props.dinamicContent.elementType) {
        case 'Page':
            return <Page {...props.dinamicContent.data} /> 
        case 'Title1':
            return <Title1 {...props.dinamicContent.data} />              
        case 'Title2':
            return <Title2 {...props.dinamicContent.data}  /> 
        case 'RowCharts':
            return <RowCharts {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'LineChart':
            return <LineChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart} /> 
        case 'PieChart':
            
            return <PieChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart} /> 
        case 'BarChart':
            return <BarChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'RadarChart':
                return <RadarChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'BubbleChart':
                return <BubbleChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'MixedChart':
            return < ComplexApexChartComponent {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/>
        case 'ApexBar':
            return < ApexChartExample {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'ChartPolar':
                return < DocumentacionChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'DoughnutChart':
                return < DoughnutChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        case 'StackedBarChart':
                return < StackedBarChart {...props.dinamicContent.data} updateSelectedChart={props.updateSelectedChart}/> 
        // case 'D3Chart' :
        //     return < D3Chart data={data} ></D3Chart>
        default:
            return ""
    }
}
 
export default DinamicElement;