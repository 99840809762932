import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Button, Card, Col, ProgressBar, Row, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { Doughnut, Line, PolarArea, Radar } from 'react-chartjs-2';

import axios from 'axios'
import ChartCard from "../components/charts/ChartCard";
import ChartCard2 from "../components/charts/ChartCard2";
import ChartCardGigante from "../components/charts/ChartCardGigante";
import ChartCardGigante2 from "../components/charts/ChartCardGigante2";


import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';


export default function PropiedadIntelectual(props) {


  const [charts, setCharts] = useState([]);

  useEffect(() => {
    // axios.get(`${process.env.REACT_APP_API_URL}charts/random-chart-produccion`)
    axios.get(`${process.env.REACT_APP_API_URL}charts/random-chart-produccion`)
      .then(response => {
        setCharts(response.data); // Asumiendo que la API devuelve un array de gráficos
        console.log("GRAFICOS ALEATORIOS", response.data);
      })
      .catch(error => console.error('Error fetching charts:', error));
  }, []);
  
  const states = {
    hover: {
      filter: {
        type: 'none'
      }
    },
    active: {
      filter: {
        type: 'none'
      }
    }
  };

  const seriesOne = [{
    data: [[13, 1400], [14, 1800], [15, 1500]]
  }, {
    data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1200], [12, 1000], [13, 0], [14, 0], [15, 0], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
  }];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: false }
    },
    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0
      },
      yaxis: {
        lines: { show: false }
      }
    },
    colors: ['#506fd9', '#c8ccd4'],
    plotOptions: {
      bar: { columnWidth: '45%' }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 0
    },
    xaxis: {
      min: 0,
      type: 'numeric',
      tickAmount: 6,
      decimalsInFloat: 0,
      labels: {
        style: { fontSize: '11px' }
      }
    },
    yaxis: {
      show: false,
      max: 2000
    },
    states: states,
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    fill: { opacity: 1 },
    legend: { show: false }
  };

  const seriesTwo = [{
    data: [
      [0, 50], [1, 50], [2, 60], [3, 50], [4, 45], [5, 55], [6, 55], [7, 45], [8, 60], [9, 25],
      [10, 55], [11, 60], [12, 55], [13, 55], [14, 55], [15, 55], [16, 65], [17, 55], [18, 75], [19, 35],
      [20, 50], [21, 65], [22, 35], [23, 45], [24, 45], [25, 45], [26, 55], [27, 50], [28, 55], [29, 55],
      [30, 55], [31, 65], [32, 45], [33, 40], [34, 45], [35, 45], [36, 55], [37, 75], [38, 65], [39, 65],
      [40, 70]
    ]
  }, {
    data: [
      [0, 50], [1, 50], [2, 60], [3, 50], [4, 45], [5, 55], [6, 55], [7, 45], [8, 60], [9, 25],
      [10, 55], [11, 60], [12, 55], [13, 55], [14, 55], [15, 55], [16, 65], [17, 55], [18, 75], [19, 35],
      [20, 50], [21, 65], [22, 35], [23, 45], [24, 45]
    ]
  }];

  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false }
    },
    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0
      },
      yaxis: {
        lines: { show: false }
      }
    },
    colors: ['#c8ccd4', '#506fd9'],
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: 1.5
    },
    xaxis: {
      min: 0,
      max: 40,
      type: 'numeric',
      tickAmount: 6,
      decimalsInFloat: 0,
      labels: {
        style: { fontSize: '11px' }
      }
    },
    yaxis: {
      show: false,
      min: 0,
      max: 140
    },
    states: states,
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    fill: { opacity: 1 },
    legend: { show: false }
  };

  const dataDonut = {
    labels: ['CORFO', 'CORFO I+D Pymes', 'Corfo súmate a innovar'],
    datasets: [{
      label: 'Hombres',
      data: [4, 6,2],
      backgroundColor: ['#009ace', '#44d62c','#ffaa4d']
    }, {
      label: 'Mujeres',
      data: [2, 5,2],
      backgroundColor: ['#ff7276', '#ea27c2','#00b796']
    }]
  };

  const optionDonut = {
    maintainAspectRatio: false,
    responsive: true,
    chart: {
      toolbar: { show: true }
    },
    plugins: {
      legend: {
        display: true,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };
// 'WoS/ISI, Scopus' H M
  const dataPolar = {

    labels:['Hombres','Mujeres'],
    datasets: [{
      label: 'WoS/ISI, Scopus',
      data: [803, 460],

      backgroundColor: ['#009ace', '#44d62c']
    }]
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: true }
    },
    plugins: {
      legend: {
        display: true,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };


  // DATA PARA doc-indexados
  const dataRadar = {
    labels: [ 'Scielo', 'Latindex', 'Capítulo de libros', 'Edición Libro', 'Libro- Referato'],
    datasets: [{
      label:'Hombres',
      data: [ 24, 33, 56, 18, 36],
      fill: true,
      backgroundColor: 'rgba(0,154,206,0.2)',
      borderColor: '#506fd9',
      borderWidth: 1.5,
      pointBackgroundColor: '#506fd9',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#506fd9'
    }, {
      label:'Mujeres',
      data: [ 19, 37, 29, 7, 34],
      fill: true,
      backgroundColor: 'rgba(68,214,44, 0.2)',
      borderColor: '#85b6ff',
      borderWidth: 1.5,
      pointBackgroundColor: '#85b6ff',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#85b6ff'
    }]
  };

  const optionRadar = {
    maintainAspectRatio: false,
    responsive: true,


    plugins: {
      legend: {
        display: true,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  const dataLine = {
    labels: ['1H', '12H', '24H', '1W', '1M', '1Y'],
    datasets: [{
      data: [50, 25, 60, 80, 20, 30],
      borderColor: '#506fd9',
      borderWidth: 1.5,
      stepped: false
    }]
  };

  const optionLine = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 10
          }
        },
        grid: {
          borderColor: '#009ace',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.18)'
        }
      },
      x: {
        grid: {
          color: 'rgba(65,80,95,.08)'
        },
        ticks: {
          color: '#6e7985',
          font: {
            size: 10,
            weight: '500'
          }
        }
      }
    }
  };

  // WOS H vs M
  const seriesThreeWoS = [{
    name: 'Mujeres',
    type: 'bar',
    data: [    [0,2],[1,2],[2,5],[3,13],[4,2],[5,15],[6,64],[7,104],[8,102],[9,155],
    [10,185],[11,234],[12,252],[13,244],[14,151]]

  }, {
    name: 'Hombres',
    type: 'area',
    data: [
      [0,2],[1,5],[2,14],[3,16],[4,24],[5,81],[6,179],[7,306],[8,300],[9,287],
      [10,344],[11,354],[12,397],[13,403],[14,251]
    ]
  }];

  const seriesThreeScopus = [{
    name: 'Mujeres',
    type: 'bar',
    data: [    [0,1],[1,2],[2,7],[3,11],[4,2],[5,11],[6,31],[7,68],[8,72],[9,102],[10,126],[11,171],[12,185],
    [13,195],[14,121]]

  }, {
    name: 'Hombres',
    type: 'area',
    data: [
      [0,2],[1,2],[2,14],[3,10],[4,18],[5,54],[6,127],[7,230],[8,227],[9,172],
      [10,227],[11,245],[12,306],[13,317],[14,212]
    ]
  }];

  const seriesThreeScielo = [{
    name: 'Hombres',
    type: 'bar',
    data: [    [0,2],[1,5],[2,15],[3,18],[4,29],[5,88],[6,199],[7,321],[8,315],[9,304],
    [10,362],[11,370],[12,421],[13,426],[14,255]]

  }, {
    name: 'Mujeres',
    type: 'area',
    data: [
      [0,2],[1,2],[2,7],[3,13],[4,3],[5,20],[6,70],[7,110],[8,108],[9,167],
      [10,190],[11,243],[12,269],[13,259],[14,155]
    ]
  }];

  const optionThree = {
  

    grid: {
      borderColor: 'rgba(72,94,144, 0.07)',
      padding: {
        top: -20,
        left: 0,
        right: 0,
        bottom: 0
      },
      yaxis: {
        lines: { show: false }
      }
    },
    colors: ['#44d62c','#009ace'],
    plotOptions: {
      bar: { columnWidth: '50%' }
    },
    stroke: {
      curve: 'straight',
      lineCap: 'square',
      width: [2,0]
    },
    xaxis: {
      overwriteCategories: ['2008','','','','2012','','','','2016','','','','','2022'],
      tickAmount: 11,
      decimalsInFloat: 0,
      lines: { show: true },
      labels: {
        style: { colors: '#009ace', fontSize: '11px' }
      }
    },

    fill: {
      opacity: [0.2, 1, 1],
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: "vertical",
        opacityFrom: 0.55,
        opacityTo: 0
      }
    },
    states: states,
    dataLabels: { enabled: false },
    tooltip: { enabled: true },
    legend: { show: true }
  };

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const {origenIn,setOrigenIn} = props;
  const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
  const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
  const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
  const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
  const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
  const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);

  useEffect(() => {
    //setOrigenIn(origenIn);
   // origenIn = 'Database' 
  //  setOrigenIn('Database')
  setMostrarWSE(false) 
  setMostrarPI(true)
  setMostrarHome(false)
  //setMostrarAyuda(false)  
  setMostrarProyectos(false)
  setMostrarBD(false)
  }, [origenIn]);



  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <React.Fragment>
      {/* <Header onSkin={setSkin} /> */}
      <div className="main main-app p-3 p-lg-4">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Producción</li>
            </ol>
            <h4 className="main-title mb-0">Producción intelectual</h4>
          </div>

{/*           <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
            <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
            <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div> */}
        </div>

        <Row className="g-3">
          <Col xs="12" sm="6" xl>
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="mb-1 text-primary ti--3"><i className="ri-article-line fs-48 lh-1 icon-red"></i></div>
                <h6 className="fw-semibold text-dark mb-1">Publicaciones (2016-2020)</h6>
                <p className="fs-xs text-secondary"><span className="ff-numerals">1,376</span> documentos</p>
                <ProgressBar now={88} className="ht-3 mb-1" />
                <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
       
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="6" sm="6" xl>
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="mb-1 text-primary ti--3"><i className="ri-book-open-line fs-48 lh-1 icon-red"></i></div>
                <h6 className="fw-semibold text-dark mb-1">Capítulo de libros (2016-2020)</h6>
                <p className="fs-xs text-secondary"><span className="ff-numerals">85</span> Documentos</p>
                <ProgressBar now={5} className="ht-3 mb-1" />
                <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="6" sm="6" xl>
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="mb-1 text-primary ti--3"><i className="ri-book-2-line fs-48 lh-1 icon-red"></i></div>
                <h6 className="fw-semibold text-dark mb-1">Edición libros (2016-2020)</h6>
                <p className="fs-xs text-secondary"><span className="ff-numerals">25</span> Files</p>
                <ProgressBar now={2} variant="warning" className="ht-3 mb-1" />
                <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
        
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="6" sm="6" xl>
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="mb-1 text-primary ti--3"><i className="ri-book-line fs-48 lh-1 icon-red"></i></div>
                <h6 className="fw-semibold text-dark mb-1">Libro- Referato (2016-2020)</h6>
                <p className="fs-xs text-secondary"><span className="ff-numerals">70</span> Files</p>
                <ProgressBar now={4} variant="danger" className="ht-3 mb-1" />
                <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
         
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="6" sm="6" xl>
            <Card className="card-one">
              <Card.Body className="p-3">
                <div className="mb-1 text-primary ti--3"><i className="ri-bar-chart-2-line fs-48 lh-1 icon-red"></i></div>
                <h6 className="fw-semibold text-dark mb-1">Diferencia promedio anual de publicaciones H-M (2016-2020)</h6>
                <p className="fs-xs text-secondary"><span className="ff-numerals">64</span> documentos en promedio</p>
                <ProgressBar now={50} className="ht-3 mb-1" />
                <div className="d-flex justify-content-between ff-numerals fs-xs fw-medium">
                 
                  <span>40% relativo menos las mujeres</span>
                </div>
              </Card.Body>
            </Card>
          </Col>
    {/*       <Col xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Data Analytics</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header> */}
   {/*            <Card.Body className="p-3">
                <Row className="g-3">
                  <Col sm="4" md="3">
                    <Row className="g-3">
                      <Col xs="4" sm="12">
                        <h2 className="card-value ls--1 mb-1">69.8<span className="opacity-75">TB</span></h2>
                        <label className="card-label fw-medium text-secondary fs-sm fs-sm-normal">Total Storage</label>
                      </Col>
                      <Col xs="4" sm="12">
                        <h2 className="card-value ls--1 mb-1">836.5<span className="opacity-75">K</span></h2>
                        <label className="card-label fw-medium text-secondary fs-sm fs-sm-normal">Object Count</label>
                      </Col>
                      <Col xs="4" sm="12">
                        <h2 className="card-value ls--1 mb-1">88.1<span className="opacity-75">MB</span></h2>
                        <label className="card-label fw-medium text-secondary fs-sm fs-sm-normal">Avg. Object Size</label>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="8" md="9">
                    <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={200} />
                  </Col>
                </Row>
              </Card.Body> 
            </Card>
          </Col>*/}
 {/*          <Col md="6" xl="4">
            <Card className="card-one card-daily-trends">
              <Card.Body className="p-3">
                <h6 className="card-title fw-semibold text-dark fs-15 mb-1">Daily Trends</h6>
                <p className="text-secondary">CPU Power - <span className="ff-numerals">11/20/2023</span></p>
                <h1 className="card-value">2836 <span>KHz</span></h1>
              </Card.Body>
              <ReactApexChart series={seriesTwo} options={optionTwo} type="area" height={'100%'} className="mt-5 mt-md-0" />
            </Card>
          </Col> */}
          <Col xs="12" sm="6"md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Articulos indexados, capítulos de libros y catalogos año 2020</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <label className="d-block fs-sm mb-2">
                  <span className="ff-numerals">64%</span> hombres y <span className="ff-numerals">36% mujeres</span> 
                </label>
                <ProgressBar className="ht-15 mb-4">
                 
                  <ProgressBar now={64} variant="ui-01"/>
                  <ProgressBar now={36} variant="ui-02" style={{ backgroundColor: 'rgba(30, 30, 30, 0.1)' }}/>
                </ProgressBar>

                {[
                  {
                    "icon": "ri-rocket-line",
                    "name": "Hombres",
                    "progress": 100,
                    "files": "124",
                    "percent": "64%"
                  }, {
                    "icon": "ri-file-text-line",
                    "name": "Mujeres",
                    "progress": 72,
                    "files": "71",
                    "percent": "36%"
                  }
                ].map((item, index) => (
                  <div className="storage-item" key={index}>
                    <div className="storage-icon">
                      <i className={item.icon}></i>
                    </div>
                    <div className="flex-fill">
                      <div className="d-flex justify-content-between mb-1">
                        <span className="fw-medium">{item.name}</span>
                        <span className="ff-numerals">{item.size}</span>
                      </div>
                      <ProgressBar now={item.progress} className="ht-5 mb-1" style={{ backgroundColor: 'rgba(30, 30, 30, 0.1)' }}/>
                      <div className="d-flex justify-content-between ff-numerals fs-xs text-secondary">
                        <span>{item.files} Documentos</span>
                        <span>{item.percent}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </Col>

          {/* <Col md="7" lg="6" xl="4">
            <Card className="card-one">
              <Card.Body className="p-4">
                <>EBCT en que participan mujeres</>
                <div className="chart-donut-three mb-4">
                  <Doughnut data={dataDonut} options={optionDonut} />
                </div>
                <Row className="g-3">
                  <Col xs="6">
           
                    <label className="d-block mb-1"></label>
                    <h2 className="card-value ls--1 mb-0">76%<span>hombres</span></h2>
                  </Col>
                  <Col xs="6">
                    <label className="d-block mb-1"></label>
                    <h2 className="card-value ls--1 mb-0">53%<span>mujeres</span></h2>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col> */}
 {/*          <Col md="5" lg="6" xl="4">
            <Card className="card-one card-article">
              <Card.Body>
                <div className="mb-3">
                  <span className="badge bg-ui-02 fs-xs">Latest Article</span>
                </div>
                <h4 className="card-title mb-3">Overhaul Cloud-based Storage Analytics and Monitoring Suite</h4>
                <p className="text-secondary mb-4">Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est similique sunt in culpa qui officia deserunt mollitia animi.</p>
                <Button as="a" variant="primary">Learn more</Button>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Publicaciones WoS/ISI + Scopus </Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <div className="ht-250">
                  <PolarArea data={dataPolar} options={optionPolar} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6" xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Artículos, capítulos de libros, libros, catálogos - periodo 2016-2020</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <div className="ht-250">
                  <Radar data={dataRadar} options={optionRadar} />
                </div>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col xl="4">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">CPU Utilization</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3">
                <div className="ht-250">
                  <Line data={dataLine} options={optionLine} />
                </div>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col xs="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Plublicaciones WoS</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-3 p-xl-4">
                <Row className="g-4">
                  <Col xl="8">
                    <>WOS</>
                    <ReactApexChart series={seriesThreeWoS} options={optionThree} height={'100%'} className="apex-chart-eleven" />
                  </Col>
                 
                  <Col xl="8"> <>Scopus</>
                    <ReactApexChart series={seriesThreeScopus} options={optionThree} height={'100%'} className="apex-chart-eleven" />
                  </Col>
                 
                  <Col xl="8"> <>Scielo</>
                    <ReactApexChart series={seriesThreeScielo} options={optionThree} height={'100%'} className="apex-chart-eleven" />
                  </Col> */}
     {/*              <Col xl>
                    {[
                      {
                        "icon": "ri-drive-fill",
                        "name": "Google Drive",
                        "text": "Google Drive is a file storage and synchronization service that allows users to store files in the cloud, synchronize files across devices, and share files."
                      }, {
                        "icon": "ri-dropbox-fill",
                        "name": "Dropbox",
                        "text": "Dropbox is a file hosting service that offers cloud storage, file synchronization, personal cloud, and client software."
                      }, {
                        "icon": "ri-cloud-fill",
                        "name": "iCloud",
                        "text": "iCloud helps you keep your most important information like photos, files, etc., and available across all your devices."
                      }
                    ].map((item, index) => (
                      <div className={"d-flex" + ((index > 0)? " mt-3" : "")} key={index}>
                        <i className={item.icon + " fs-48 lh-1 me-3 text-primary"}></i>
                        <div>
                          <h6 className="fw-semibold text-dark mb-1">{item.name}</h6>
                          <p className="fs-sm text-secondary mb-0">{item.text}</p>
                        </div>
                      </div>
                    ))}
                  </Col> */}
                {/* </Row>
              </Card.Body>
            </Card> */}
          {/* </Col> */}
        </Row>
        <Row>
          <Col>
          <div className="main-card-proyecto" style={{ marginTop: "18px" }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
          {charts.slice(0, 4).map((chart, index) => (
            <ChartCard2 key={index} chart={chart}/>
          ))}
        </div>

            {charts.length > 0 && (
              <ChartCardGigante2 chart={charts[4]}/>  // Usar ChartCardGigante2 para el ultimo gráfico
            )}
</div>  
</Col>  
          </Row>

        <Footer />
      </div>
    </React.Fragment>
  )
};