// BarChart.js
import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import "./charts.css";
import { barOptions } from "./ChartOptions";

function BarChart(chartData) {
  // Guardar copia de las etiquetas originales para los tooltips
  const originalLabels = [...chartData.labels];

  // Generar las opciones del gráfico pasando las etiquetas originales
  const options = barOptions(originalLabels);

  return (
    <Card className={"chart-back"}>
      <Card.Body>
        <Card.Title className={"card-text"}>{chartData.cHeader}</Card.Title>
        {chartData.cMetadata && 
          <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
              {chartData.cMetadata}
          </div>
        }
        <div style={{ height: '300px' }}>
          <Bar data={chartData} options={options} />
        </div>
        <Card.Text className={"card-text"}>{chartData.cText}</Card.Text>
      </Card.Body>
    </Card>  
  );
}

export default BarChart;
