import { React, useRef } from "react";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import "./charts.css";
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { pieOptions } from "./ChartOptions";
//Grafico circular percentajes

Chart.register(ChartDataLabels);

function PieChart( {updateSelectedChart, ...chartData} ){    
  //Se seleccionan las opciones para el grafico
 //alert(chartData.datasets[0].data)
  const options =  {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    plugins: {
      
         datalabels: {
          align: function(context) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
          //  var invert = Math.abs(value) <= 1;
        // alert(value)
            return value
          },
         formatter: function(value,context) {
          let sum = 0;
          let dataArr = context.dataset.data;
          dataArr.map(data => {
            sum += data;
        });
           return Math.round(value*100/sum) + '%';
         },
        },
        legend: {
            display: true,
            position: "top",
            align:"start"
                   
    },
  },}



  const chartRef = useRef();

  //Evento al momento de hacer click en el grafico
  //  Se realiza la llamada al backend para obtener los graficos secundarios
  const onClick = async (event) =>{
    try {
        var ele = getElementAtEvent(chartRef.current, event);   
        var index = ele[0].index;
        var label = chartData.labels[index];
        var _idChart=chartData._id;
        var _idPage=chartData._idPage;
   
        const url = process.env.REACT_APP_API_URL+"secondaryRowCharts/rowCharts?_idPage="+_idPage+"&_idChart="+_idChart+"&filter="+label;
        let response = await fetch(url, {mode: "cors"});
        const data1 = await response.json();
        if(data1!==undefined && typeof updateSelectedChart === 'function')
        updateSelectedChart(data1);
    } catch (e) {
        console.error(e)
    }
  }

  //alert(chartData.labels)
   // console.log("LABELS ----------------")
  // console.log(data.labels)
   //var i = 0
   for(var i=0;i<chartData.labels.length;i++)
   {

     if(chartData.labels[i]==='H')
     {chartData.labels[i]='Hombre'}
     if(chartData.labels[i]==='M')
     {chartData.labels[i]='Mujer'}
   }
  //   console.log(data.labels[i])
  // }

 // alert(chartData.labels)
  // vamos a sacar temporalmente el sub-gráfico
  return (
  //   <Card className={"chart-back"} align="center">//   
<Card className="card-one">
    <Card.Body>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Card.Title align="left" className={"card-text"}>
                {chartData.cHeader}
            </Card.Title>
            {chartData.cMetadata && 
                <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    {chartData.cMetadata}
                </div>
            }
        </div>
        <div style={{ height: '300px' }}>
            <Pie data={chartData} options={pieOptions} ref={chartRef} />
        </div>
        <Card.Text align="center" className={"card-text p-2"}>
            {chartData.cText}
        </Card.Text>
    </Card.Body>
</Card>
  );
}


export default PieChart;