import React from "react";
import { Bubble } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import "./charts.css";

const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    datalabels: {
      display: false,
    },
  },
  scale: {
    ticks: { beginAtZero: true },
  },
};

function BubbleChart(chartData) {
  console.log("DATOS GRAFICO", chartData)
  return (
    <Card className={"chart-back"}>
      <Card.Header className={"card-text"}>{chartData.cHeader}</Card.Header>
      <Card.Body>
        <Card.Title className={"card-text"}>{chartData.cTitle}</Card.Title>
        <Bubble data={chartData} options={options} />
        <Card.Text className={"card-text"}>{chartData.cText}</Card.Text>
      </Card.Body>
    </Card>
  );
}


export default BubbleChart;