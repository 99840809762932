import React from 'react';
import './charts.css';
import { Card } from 'react-bootstrap';
import { Bar, Doughnut, Pie, Radar, Line } from 'react-chartjs-2';
import {
  barOptions,
  doughnutOptions,
  pieOptions,
  radarOptions,
  lineOptions,
  stackedbarOptions
} from './ChartOptions';
import ReactApexChart from "react-apexcharts";

const optionThree = {
  

  grid: {
    borderColor: 'rgba(72,94,144, 0.07)',
    padding: {
      top: -20,
      left: 0,
      right: 0,
      bottom: 0
    },
    yaxis: {
      lines: { show: false }
    }
  },
  colors: ['#44d62c','#009ace'],
  plotOptions: {
    bar: { columnWidth: '50%' }
  },
  stroke: {
    curve: 'straight',
    lineCap: 'square',
    width: [2,0]
  },
  xaxis: {
    overwriteCategories: ['2008','','','','2012','','','','2016','','','','','2022'],
    tickAmount: 11,
    decimalsInFloat: 0,
    lines: { show: true },
    labels: {
      style: { colors: '#009ace', fontSize: '11px' }
    }
  },

  fill: {
    opacity: [0.2, 1, 1],
    gradient: {
      inverseColors: false,
      shade: 'light',
      type: "vertical",
      opacityFrom: 0.55,
      opacityTo: 0
    }
  },
  dataLabels: { enabled: false },
  tooltip: { enabled: true },
  legend: { show: true }
};

const seriesThreeWoS = [{
  name: 'Mujeres',
  type: 'bar',
  data: [    [0,2],[1,2],[2,5],[3,13],[4,2],[5,15],[6,64],[7,104],[8,102],[9,155],
  [10,185],[11,234],[12,252],[13,244],[14,151]]

}, {
  name: 'Hombres',
  type: 'area',
  data: [
    [0,2],[1,5],[2,14],[3,16],[4,24],[5,81],[6,179],[7,306],[8,300],[9,287],
    [10,344],[11,354],[12,397],[13,403],[14,251]
  ]
}];


const ChartCardGigante2 = ({ chart }) => {
  const data = {
    labels: chart.labels,
    datasets: chart.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      borderWidth: dataset.borderWidth,
    }))
  };

  const getChartComponent = (type) => {
    switch (type) {
      case 'LineChart':
        return <Line data={data} options={lineOptions} />;
      case 'StackedBarChart':
        return <Bar data={data} options={stackedbarOptions(data.labels)} />;
      default:
        return <div>No chart available for type {type}</div>;
    }
  };

  return (
    <Card className="m-2 full-width-chart" style={{
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <div className="chart-title">{chart.cHeader}</div>
      <Card.Body>
        <div style={{ height: '100%', minHeight: '400px' }}>
          {getChartComponent(chart.type)}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartCardGigante2;
