import React, { useEffect, useState } from "react";
import { Link, json , useNavigate } from "react-router-dom";
import { Button, Card, Col, Nav, ProgressBar, Row ,Form, ListGroup, Modal } from "react-bootstrap";
import Footer from "./Footer";
import CardsGraficos from "../components/CardsGraficos";
import DinamicElement from "../components/DinamicElement";
import DescargaPlanillas from "../components/DescargaPlanillas";
import chartManager from "../hooks/chartManager";
import pdfgenerator from "../hooks/pdfgeneratorManager";
import {MyEstadoGlobalContext} from '../layouts/MyEstadoGlobalContext';
import NavBarMenu from "../components/NavBarMenu";
import axios from "axios";



// function updateProcedencia(input)   
// {
//   // setProcedenciaIn(input);
//   console.log("input: --- "+input)
//   //console.log(procedenciaIn)
//   CentralSE({procedencia:input})
// }

// function updateId(input)   
// {
//   // setProcedenciaIn(input);
//   console.log("input: --- "+input)
//   //console.log(procedenciaIn)
//   CentralSE({procedencia:input})
// }

let View_tmp;

function CentralSE(props) {

const {respuestas,consulta,procedencia,idIn,setIdq} = props;
const [procedenciaIn, setProcedenciaIn] = useState(procedencia);
const [viewCentral, setViewCentral] = useState('');
const [id, SetId] = useState(null);
const [idG, SetIdG] = useState(null);

const {mostrarWSE, setMostrarWSE} = React.useContext(MyEstadoGlobalContext);
const {mostrarBD, setMostrarBD} = React.useContext(MyEstadoGlobalContext);
const {mostrarProyectos, setMostrarProyectos} = React.useContext(MyEstadoGlobalContext);
const {mostrarPI, setMostrarPI} = React.useContext(MyEstadoGlobalContext);
const {mostrarHome, setMostrarHome} = React.useContext(MyEstadoGlobalContext);
const {mostrarAyuda, setMostrarAyuda} = React.useContext(MyEstadoGlobalContext);

const [colSize, setColSize] = useState("4")
const [tipoGrafico, setTipoGrafico] = useState("");
// if(View_tmp.props.dinamicContent.data.type == "MixedChart")
const [json1, setJson] = useState(null);
const [versionJson, setVersionJson] = useState();
const [showModal, setShowModal] = useState(false);
const handleToggleModal = () => setShowModal(!showModal);
const mobileWidthThreshold = 768;
const [imageBase64, setImageBase64] = useState('');
const [csvData, setCsvData] = useState('');
const estadisticas = [
  { nombre: 'Resultados de Búsqueda', valor: 120 },
  { nombre: 'Visualizaciones', valor: 300 },
  { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  // { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  // { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  // { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  // { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  // { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },
  // { nombre: 'Búsquedas del mismo tipo (último mes)', valor: 90 },


];

const navigate = useNavigate();


const handleGeneratePDF = async () => {
  try {
      const response = await axios.post('http://localhost:3000/api/v1/generate-pdf/apex', {
          imageBase64,
          csvData
      }, {
          responseType: 'blob' // Para recibir la respuesta como un Blob
      });

      // Crear un objeto Blob y generar una URL para descargar el PDF
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      // Crear un enlace para descargar el PDF y hacer clic en él
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'chart-report.pdf');
      document.body.appendChild(link);
      link.click();

      // Limpiar la URL creada
      window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error('Error al generar el PDF:', error);
  }
};


const [busqueda, SetBusqueda] = useState(
  {
    busqueda: 'random', 
    chart: ["1"], // Aca en version final un listado aleatorio de gráficos
  },
);

const [busqueda2, SetBusqueda2] = useState(
  
  {
    busqueda: 'random', 
    chart: ["1","2","3"], // Aca en version final un listado aleatorio de gráficos
  },

);

const [pantalla, SetPantalla] = useState(
  {
    busqueda: false, 
    chart: "", // Aca en version final un listado aleatorio de gráficos

  }
);
const [listado, setListado] = useState([]);
const [seleccionado, setSeleccionado] = useState(false);


function esArregloDeStrings(variable) {
  if (!Array.isArray(variable)) {
    return false;
  }
  
  return variable.every(function(elemento) {
    return typeof elemento === 'string';
  });
}


useEffect(() => {

  //alert("En generacion de lista")
  const cambiarBusqueda = () => {
    if(esArregloDeStrings(busqueda2.chart))
    {
     // alert("B1 Si: "+busqueda2.chart)
     SetId(busqueda2.chart[0])
      setListado(busqueda2.chart.map((x) => {
        return { ChartId: x, ChartSelected: false };
      }));
 
    }//
  //  alert("B1: "+busqueda2.chart)
  //  alert("B2: "+busqueda.chart)     
  }
  cambiarBusqueda();

}, [ busqueda2 ]);

// useEffect(() => {
//   // Asume que View_tmp y sus props están correctamente definidos
//   const tipoGrafico = View_tmp.props.dinamicContent.data.type;
//   if (tipoGrafico === "MixedChart") {
//     setColSize("8");
//   } else {
//     setColSize("4");
//   }
// }, [View_tmp]);

const ElegirParaImprimir = (e) => {
  var temp = listado;
/*   console.log("################################ ->1 ")
  console.log(listado)
  console.log(pantalla.chart)
  console.log("ID: "+id)
  console.log("e: "+e)
  console.log("Rank :"+respuestas)
  console.log("################################ ->2 ") */
  const elemento = listado.find((x) => { return x.ChartId === e});
  //console.log(elemento)
  if (elemento!=undefined){
  //  console.log("################################")
  const pos = listado.indexOf(elemento);
  const resultado = !temp[pos].ChartSelected;
  temp[pos].ChartSelected = resultado;
  setSeleccionado(resultado);
  setListado(temp);
  //console.log("################################")
  }
}





const MandarAImprimir = () => {
  var Seleccion = listado.map((x) => { 
        if (x.ChartSelected){
          return x.ChartId;
        }
  })
  var filtered = Seleccion.filter(function(x) {
    return x !== undefined;
  });
  pdfgenerator.GetPDF(filtered);
}

const CambiarPantalla = (e) => {
  //alert(e)
  //alert(listado)
  
 // SetPantalla(e);
  SetId(e)
  if (window.innerWidth <= mobileWidthThreshold) {
    handleToggleModal(); 
  }

  const elemento = listado.find((x) => { return x.ChartId === e});
  //alert(listado)
  if (elemento!=undefined){
  const pos = listado.indexOf(elemento);
  //alert("Pos: "+pos)
  setSeleccionado(listado[pos].ChartSelected);
  }

}

/* function CambiarPantalla(e) {
  alert(e)
  alert(listado)
  
 // SetPantalla(e);
  alert("listado.length: "+listado.length)
  if(listado.length > 0)
  {
  const elemento = listado.find((x) => { return x.ChartId === e});
  alert(listado)
  const pos = listado.indexOf(elemento);
  alert("Pos: "+pos)
  setSeleccionado(listado[pos].ChartSelected);
  }
} */



console.log("Call Central input procedencia: ---------------- "+procedencia)
console.log("Call Central input respuestas: ---------------- "+respuestas)
//const {origenIn,setOrigenIn} = props;

useEffect(() => {
//alert("En carga de busq")  
let HTMLTag = document.getElementsByClassName("main");
console.log("HTMLTag df")
console.log(HTMLTag)
//console.log(procedenciaIn)
console.log(procedencia)
setMostrarWSE(true)
setMostrarBD(false)
SetBusqueda2({busqueda: true, chart: respuestas}) 
setMostrarPI(false)
setMostrarHome(false)
setMostrarProyectos(false)
setMostrarAyuda(false)
console.log("procedenciaIn: "+procedenciaIn)
if(procedenciaIn === 'App')
{
  setMostrarWSE(false)
  setMostrarHome(true)
  navigate("/home")
}




}, [procedencia,respuestas,consulta,setMostrarWSE,procedenciaIn]); // Only re-run the effect if count changes

//}, [procedencia,respuestas,consulta,setMostrarBD,setMostrarWSE]); // Only re-run the effect if count changes

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-link"
  >
    {children}
  </Link>
));

// const toggleSidebar = (e) => {
//   e.preventDefault();
//   let isOffset = document.body.classList.contains("sidebar-offset");
//   if (isOffset) {
//     document.body.classList.toggle("sidebar-show");
//   } else {
//     if (window.matchMedia("(max-width: 991px)").matches) {
//       document.body.classList.toggle("sidebar-show");
//     } else {
//       document.body.classList.toggle("sidebar-hide");
//     }
//   }
// }

 

  // console.log("console.log(Id): "+id)
  // console.log("FIN id ")

//const x = JSON.stringify(Object.values(chartManager.GetChartsId(id)));
//var x = 'null'
var x = JSON.stringify(chartManager.GetChartsId(id));
//const x = JSON.stringify(Object.values(chartManager.GetChartsId(id)));


var json = '';
var titulo = '';


  if(x !== 'null') {
    json = JSON.parse(x);
    titulo = json["cHeader"];
    

  
    // Configurar View_tmp
    View_tmp = <DinamicElement dinamicContent={{data: json, elementType: json["type"]}} key={"ChartsVector-item-" + id} />;
  } else {
    View_tmp = "";

  }
  console.log("VIEW TEMPPP", View_tmp) 
  
      // Usar json.type directamente para determinar el tamaño de la columna
    // const tipoGrafico = json["type"];
    // if (tipoGrafico === "MixedChart") {
    //   setColSize("8"); // Para gráficos MixedChart, usar un tamaño más grande
    // } else {
    //   setColSize("4"); // Tamaño predeterminado para otros tipos de gráficos
    // }

    useEffect(() => {
      if (x !== 'null') {
        const parsedJson = JSON.parse(x);
        setJson(parsedJson); // Actualizamos el estado json cada vez que x cambia
      } else {
        setJson(null);
      }
    }, [x]);

    // useEffect(() => {
    //   if (json1) {
    //     const titulo = json1["cHeader"]; // Si necesitas usar `titulo` en alguna parte del componente, considere establecerlo en el estado también
    //     // Configuramos ViewTmp basado en el estado json actualizado
    //     setViewTmp(<DinamicElement dinamicContent={{ data: json, elementType: json["type"] }} key={"ChartsVector-item-" + props.id} />);
    //   } else {
    //     setViewTmp("Cargando gráfico..."); // O cualquier otro placeholder
    //   }
    // }, [json1, props.id]);

    // useEffect(() => {
    //   const versionJson = json1["version"];
    //   // if (json1) {
    //   //   const tipoGrafico = json1["type"];
    //   //   if (tipoGrafico === "MixedChart" || tipoGrafico === "ApexBar") {
    //   //     setColSize("8"); // Para gráficos MixedChart, usar un tamaño más grande **sin uso
    //   //   } else {
    //   //     setColSize("7"); // Tamaño predeterminado para otros tipos de gráficos
    //   //   }
    //   // }
    // }, [json1]);

    useEffect(() => {
      // Asegúrate de que json1 no es null antes de intentar acceder a sus propiedades
      if (json1) {
        const version = json1["version"];
        setVersionJson(version); // Actualizamos el estado con el valor de version
      }
    }, [json1]);



    return (
      
      <div className="main main-app p-3 p-lg-4">
        <Modal show={showModal} onHide={handleToggleModal} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>
    <div className="d-flex gap-2 mt-3 mt-md-0">
      {/* <Button variant="" className="btn-white d-flex align-items-center gap-2"> 
        <i className="ri-share-line fs-18 lh-1"></i>Share
      </Button> */}
      {/* <Button variant="" className="btn-white d-flex align-items-center gap-2">
        <i className="ri-printer-line fs-18 lh-1"></i>Imprimir
      </Button> */}
      <Button variant="primary" className="d-flex align-items-center gap-2"onClick={() => {MandarAImprimir()}}>
        <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generar reporte
      </Button>
      {/* <button onClick={handleGeneratePDF}>Generar PDF</button> */}
    </div></Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div className="card border-primary mb-3">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Form.Check 
              id="ChartCheckbox"
              className="fs-8" 
              type="checkbox" 
              checked={seleccionado} 
              label="Seleccionar gráfico para incluir a reporte" 
              onChange={(e) => {
                ElegirParaImprimir(id);
              }} 
            />
           {versionJson === "movil" ? <span className="material-icons">phone_iphone</span> : <span className="material-icons">computer</span>}
          </div>
          {View_tmp}
        </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleToggleModal}>
      Cerrar
    </Button>
  </Modal.Footer>
</Modal>
        <div className="d-md-flex align-items-center justify-content-between ">      
    <div>
      <ol className="breadcrumb fs-sm mb-0">
        <li className="breadcrumb-item"><Link to="#">Sección</Link></li>
        <li className="breadcrumb-item active" aria-current="page">Motor de búsqueda</li>
        {/* <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4"><i className="ri-menu-2-fill"></i></Link> */}
       {/* <NavBarMenu dropdownTitle="charts" SetChart={(e) => {ClickSetChart(e)} } />  */}
       {/* <Link to="/" >  */}       
      </ol>
      {/* <h4 className="main-title mb-0">Welcome to Dashboard</h4> */}
       {/* <NavBarMenu dropdownTitle="Proyectos" SetChart={(e)=>{CambiarPantalla(e)}} />  */}
    </div>   
    <div className="d-flex gap-2 mt-3 mt-md-0">
      {/* <Button variant="" className="btn-white d-flex align-items-center gap-2"> 
        <i className="ri-share-line fs-18 lh-1"></i>Share
      </Button> */}
      {/* <Button variant="" className="btn-white d-flex align-items-center gap-2">
        <i className="ri-printer-line fs-18 lh-1"></i>Imprimir
      </Button> */}
      <Button variant="primary" className="d-flex align-items-center gap-2"onClick={() => {MandarAImprimir()}}>
        <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generar<span className="d-none d-sm-inline"> Reporte</span>
      </Button>
      {/* <button onClick={handleGeneratePDF}>Generar PDF</button> */}
    </div>
  </div>    
          <Row className="h-100">
              {/* Columna para la tarjeta que ocupa todo el largo */}
              <Col md={6} className="mb-3">
                  <Card className="h-100">
                      <Card.Body className="p-3" style={{ height: '650px' }} >
                      < div className="h-100 p-2 ">
      {/* <Card className="card-one">
        <Card.Body> */}
          {/* <ReactApexChart series={seriesFour} options={optionFour} height={120} type="line" className="mb-1" /> */}
          {/* <h3 className="card-value">0.9:8</h3>
          <ProgressBar now={50} className="ht-5 mb-2" />
          <label className="fw-semibold text-dark mb-1">Quick Ratio Goal: 1.0 or higher</label>
          <p className="fs-sm text-secondary mb-0">Measures your Accounts Receivable / Current Liabilities</p>
           */}
{/* <div>{consulta} </div>           
<div>{respuestas} </div> */}
{/* <CardsGraficos Lista={respuestas} consulta={consulta} /> */}
{/* {View_tmp} */}
{ consulta ? 
        /*             <CardsGraficos Lista={respuestas} consulta={consulta} SetId={(e) => {SetId(e)}}/>  */
        <>
   
   {/* <CardsGraficos Lista={respuestas} consulta={consulta} SetId={SetId}/>    */}
                    <CardsGraficos Lista={respuestas} consulta={consulta} SetId={(e)=>{CambiarPantalla(e)}}  />     
                    </>                        
                    :
                      <>
                        <h3 className="card-value">Sin resultados, pruebe agregando más términos (palabras clave) a su búsqueda ...</h3>
                        {/*<h3 className="card-value">0.9:8</h3>
                        <ProgressBar now={50} className="ht-5 mb-2" />
                        <label className="fw-semibold text-dark mb-1">Quick Ratio Goal: 1.0 or higher</label>
                        <p className="fs-sm text-secondary mb-0">Measures your Accounts Receivable / Current Liabilities</p>*/}
                      </>
                  }
      </div>
                      </Card.Body>
                  </Card>
              </Col>

              {/* Columna para las otras dos tarjetas, una encima de la otra */}
              <Col md={6}>
  {/* Primera tarjeta pequeña */}
  <Card style={{ display: window.innerWidth > 768 ? 'block' : 'none' }}>
    <Card.Body className="p-3" >
      <Col>
        <div className="card border-primary mb-3">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Form.Check 
              id="ChartCheckbox"
              className="fs-5" 
              type="checkbox" 
              checked={seleccionado} 
              label="Seleccionar gráfico para incluir a reporte" 
              onChange={(e) => {
                ElegirParaImprimir(id);
              }} 
            />
           {versionJson === "movil" ? <span className="material-icons">phone_iphone</span> : <span className="material-icons">computer</span>}
          </div>
          {View_tmp}
        </div>
      </Col>
    </Card.Body>
  </Card>                

  <Card className="card-estadisticas">
    <Card.Body>
      <h5>Estadísticas de Búsqueda</h5>
      <ListGroup variant="flush">
        {estadisticas.map((item, index) => (
          <ListGroup.Item variant="info" key={index}>
            {item.nombre}: {item.valor}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card.Body>
  </Card>

  {/* Segunda tarjeta pequeña */}

</Col>

          </Row>

          <Footer />
      </div>
  );
}

export { CentralSE };