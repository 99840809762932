import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';

const ComplexApexChartComponent = (chartData) => {

      const yaxisColors = ["#008FFB", "#00E396", "#FEB019"];
      
      const options = {
        chart: {
          type: chartData.typeChart, // //Dinamico
          height: 350,
          animations: {
            enabled: true, // Habilita las animaciones al cargar el gráfico
            easing: 'easeinout', // Tipo de efecto de la animación
            speed: 800, // Duración de la animación en milisegundos
            animateGradually: {
              enabled: true,
              delay: 150 // Retraso antes de que comience la animación
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350 // Velocidad de la animación para los cambios dinámicos
            }
          },
        },
        stroke: {
          width: [1, 1, 4] // Anchos de línea para cada serie
        },
        title: {
          text: chartData.cTitle, //Dinamico
          align: "left"
        },


        yaxis: chartData.series.map((serie, index) => ({
            seriesName: serie.name,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: yaxisColors[index % yaxisColors.length], // Asegura que el color se repita si hay más series que colores definidos
            },
            labels: {
              style: {
                colors: yaxisColors[index % yaxisColors.length],
              },
            },
            title: {
              // Usa el nombre de la serie para el título y agrega un texto adicional según sea necesario
              text: `${serie.name}`, // (${index === 0 ? "millones" : index === 1 ? "millones" : "%"})
              style: {
                color: yaxisColors[index % yaxisColors.length],
              },
            },
            opposite: index !== 0, // Pone los ejes Y en el lado opuesto para todas las series excepto la primera
          })),
        
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetY: 30,
            offsetX: 60
          }
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        },
        xaxis: {
          categories: chartData.category //Dinamico
        },
        dataLabels: {
          enabled: false
        },
      };
    console.log("DATA CHART", chartData)

    return (
        <div id="chart">
            <ReactApexChart key={Date.now()}  options={options} series={chartData.series} type={chartData.typeChart} height={350} />
        </div>
        // <div>
        //     {/* Itera sobre chartsConfig para renderizar un gráfico por cada configuración */}
        //     {chartData.map((chartData, index) => (
        //         <div key={index}>
                   
        //             {/* <ReactApexChart options={chartData.options} series={chartData.series} type={chartData.typeChart} height={chartData.options.chart.height} /> */}
        //         </div>
        //     ))}
        // </div>

        // height={chartData.options.chart.height}
    );
};

export default ComplexApexChartComponent;