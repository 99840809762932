// Función para acortar etiquetas
const shortenLabel = (label, maxLength = 10) => {
    if (label.length > maxLength) {
      return label.split(' ')
                  .map(word => word[0])
                  .join('');
    }
    return label;
  };

  export const doughnutOptions = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: false
        },
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 20,
            padding: 5,
            font: {
              size: 10
            }
          },
          align: "center",
          fullWidth: false,
          maxHeight: 100,
          maxWidth: 200,
        },
      },
      layout: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      }
  }

  export const doughnutOptions2 = {
    maintainAspectRatio: false,
    responsive: true,
    chart: {
      toolbar: { show: true }
    },
    plugins: {
      legend: {
        display: true,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };
  
  export const pieOptions = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          align: function(context) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            return value
          },
          formatter: function(value, context) {
            let sum = 0;
            let dataArr = context.dataset.data;
            dataArr.map(data => {
              sum += data;
            });
            return Math.round(value * 100 / sum) + '%';
          },
        },
        legend: {
          display: true,
          position: "top",
          align: "start"
        },
      }
  }
  
  export const lineOptions = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      maintainAspectRatio: true,
      plugins: {
        datalabels: {
          display: false
        },
        legend: {
          display: true,
          position: "top",
          align: "start"
        },
      },
  }
  
// ChartOptions.js
export const barOptions = (originalLabels) => ({
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      ticks: {
        autoSkip: false,
        autoSkipPadding: 10,
        maxRotation: 90,  // Ángulo máximo de rotación
        minRotation: 0,  // Ángulo mínimo de rotación
        callback: function(value, index) {
          const label = originalLabels[index];
          if (label.length > 27) {
            return label.split(' ').map(word => word[0]).join('');
          }
          return label;
        }
      }
    }
  },
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      display: true,
      position: "top",
      align: "start"
    },
    tooltip: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        title: function(tooltipItems, data) {
          return originalLabels[tooltipItems[0].dataIndex];
        }
      }
    }
  },
});


  
  export const radarOptions = {
    maintainAspectRatio: false,
    responsive: true,
      plugins: {
        datalabels: {
          display: false,
        },
      },
      scale: {
        ticks: { beginAtZero: true },
      },
  }

  export const polarOpcions = {
    maintainAspectRatio: false,
    responsive: true,
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: true }
    },
    plugins: {
      legend: {
        display: true,
      }
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };
  
  export const stackedbarOptions = (originalLabels) => ({
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
          autoSkipPadding: 10,
          maxRotation: 90,  // Ángulo máximo de rotación para las etiquetas
          minRotation: 0,  // Ángulo mínimo de rotación para las etiquetas
          callback: function(value, index) {
            const label = originalLabels[index];
            if (label.length > 5) {
              return label.split(' ').map(word => word[0]).join('');
            }
            return label;
          }
        }
      },
      y: {
        stacked: true,
        ticks: {
          callback: function(value) {
            return value.toLocaleString(); // Formatea los números con separadores de miles
          }
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          boxWidth: 20,
          padding: 5,
          font: {
            size: 10
          }
        }
      },
      datalabels: {
        display: false
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function(tooltipItems, data) {
            return originalLabels[tooltipItems[0].dataIndex];
          }
        }
      }
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      }
    }
});


  