import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import "./charts.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const chartData = {
  labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"], //cambio actualizacion-home
  datasets: [
    {
      type: 'bar',
      label: 'Acreditados',
      data: [20, 30, 40, 50, 60, 70, 80, 90, 50, 60, 70, 30],
      borderColor: "#3d3936",
      backgroundColor: "rgba(61, 57, 54, 0.2)",
      borderWidth: 3, 
    },
    {
      type: 'line',
      label: 'No Acreditados',
      data: [30, 20, 50, 40, 70, 60, 90, 80, 60, 70, 50, 40],
      fill: false,
      borderColor: "#e2211c",
      backgroundColor: "rgba(226, 33, 28, 0.2)",
      tension: 0.1
    }
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true
    }
  },
  plugins: {
    legend: {
      display: true,
      position: 'top'
    },
    datalabels: {
        display: false,
      },
  }
};

function ComboChart() {
  return (
    <Card className="chart-back">
      <Card.Body>
        <Card.Title className="card-text">Combo Chart</Card.Title>
        <div style={{ width: '70%', height: '300px', margin: '0 auto' }}>
          <Bar data={chartData} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
}

export default ComboChart;

