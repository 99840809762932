import React from 'react';
import './charts.css';
import { Card } from 'react-bootstrap';
import { Bar, Doughnut, Pie, Radar, Line, PolarArea } from 'react-chartjs-2';
import {
  barOptions,
  doughnutOptions,
  pieOptions,
  radarOptions,
  doughnutOptions2,
  polarOpcions,
  lineOptions,
  stackedbarOptions
} from './ChartOptions';

const ChartCard2 = ({ chart, style }) => {  // Acepta la prop 'style'
  const data = {
    labels: chart.labels,
    datasets: chart.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      borderWidth: dataset.borderWidth,
    }))
  };

  const getCardStyle = (type) => {
    switch (type) {
      case 'DoughnutChart':
        return 'chart-card doughnut-chart-inte';
      case 'RadarChart':
        return 'chart-card radar-chart-inte';
      case 'PolarAreaChart':
        return 'chart-card polar-chart-inte';
      case 'StackedBarChart':
        return 'chart-card stacked-bar-chart'
      default:
        return 'chart-card';
    }
  };

  const getChartComponent = (type) => {
    switch (type) {

      case 'RadarChart':
        return <Radar data={data} options={radarOptions} />;
      case 'DoughnutChart':
          return <Doughnut data={data} options={doughnutOptions} />;
      case 'PolarAreaChart':
        return <PolarArea data={data} options={polarOpcions} />;
      case 'LineChart':
        return <Line data={data} options={lineOptions} />;
      case 'StackedBarChart':
        return <Bar data={data} options={stackedbarOptions(data.labels)} />;
      default:
        return <div>No chart available for type {type}</div>;
    }
  };

  // Aplica el estilo adicional al Card
  return (
    <Card
  className={`m-2 ${getCardStyle(chart.type)}`}
  style={{
    ...style,
    boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
  }}
>
      <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: '1.2',
        fontWeight: 'bold',
      }}
        title={chart.cHeader}
      >
        {chart.cHeader}
      </div>
      <Card.Body>
        <div style={{ height: '100%', minHeight: '200px',}}>
          {getChartComponent(chart.type)}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartCard2;
