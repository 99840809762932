import React from 'react';
import './charts.css';
import { Card } from 'react-bootstrap';
import { Bar, Doughnut, Pie, Radar, Line } from 'react-chartjs-2';
import {
  barOptions,
  doughnutOptions,
  pieOptions,
  radarOptions,
  lineOptions,
  stackedbarOptions
} from './ChartOptions';

const ChartCardGigante = ({ chart }) => {
  const data = {
    labels: chart.labels,
    datasets: chart.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: dataset.backgroundColor,
      borderColor: dataset.borderColor,
      borderWidth: dataset.borderWidth,
    }))
  };

  const getChartComponent = (type) => {
    switch (type) {
      case 'BarChart':
        return <Bar data={data} options={barOptions(data.labels)} />;
      case 'DoughnutChart':
        return <Doughnut data={data} options={doughnutOptions} />;
      case 'PieChart':
        return <Pie data={data} options={pieOptions} />;
      case 'RadarChart':
        return <Radar data={data} options={radarOptions} />;
      case 'LineChart':
        return <Line data={data} options={lineOptions} />;
      case 'StackedBarChart':
        return <Bar data={data} options={stackedbarOptions(data.labels)} />;
      default:
        return <div>No chart available for type {type}</div>;
    }
  };

  return (
    <Card className="m-2 full-width-chart" style={{
      boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <div className="chart-title">{chart.cHeader}</div>
      <Card.Body>
        <div style={{ height: '100%', minHeight: '400px' }}>
          {getChartComponent(chart.type)}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartCardGigante;
