import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import { Chart as ChartJS } from "chart.js/auto";
import "./charts.css";
import { doughnutOptions } from "./ChartOptions";

const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 20,
          padding: 5, // Reduce el espacio para ajustar más contenido
          font: {
            size: 10 // Reduce más el tamaño de la fuente para hacer los textos más pequeños
          }
        },
        align: "center",
        fullWidth: false, // Asegura que la leyenda ocupe todo el ancho disponible
        maxHeight: 100,
        maxWidth: 200, // Puedes ajustar esto según la altura máxima deseada para la leyenda
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0, // Puede necesitar ajuste para asegurarse de que la leyenda no se corte
        bottom: 0,
        left:0
      }
    }
  }
  
  

// Gráfico de dona
function DoughnutChart(chartData) {
  return (
    <Card className={"chart-back"}>
      <Card.Body>
        <Card.Title className={"card-text"}>{chartData.cHeader}</Card.Title>
        {chartData.cMetadata && 
          <div style={{ fontWeight: 'bold', textAlign: 'right' }}>
            {chartData.cMetadata}
          </div>
        }
        <div style={{ height: '300px' }}>
          <Doughnut data={chartData} options={doughnutOptions} />
        </div>
        <Card.Text className={"card-text"}>{chartData.cText}</Card.Text>
      </Card.Body>
    </Card>  
  );
}

export default DoughnutChart;
