import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Card } from 'react-bootstrap';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ['Enero', 'Febrero', 'Marzo', 'Abril'],
  datasets: [
    {
      label: 'Santiago',
      data: [300, 50, 100, 80],
      backgroundColor: [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
      cutout: '80%',
    },
    {
      label: 'Temuco',
      data: [150, 100, 150, 200],
      backgroundColor: [
        'rgba(255, 159, 64, 0.5)',
        'rgba(153, 102, 255, 0.5)',
        'rgba(201, 203, 207, 0.5)',
        'rgba(233, 162, 235, 0.5)',
      ],
      borderColor: [
        'rgba(255, 159, 64, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(201, 203, 207, 1)',
        'rgba(233, 162, 235, 1)',
      ],
      borderWidth: 1,
      cutout: '60%',
    },
    {
      label: 'Talca',
      data: [100, 200, 300, 400],
      backgroundColor: [
        'rgba(255, 205, 86, 0.5)', // Cambiado para diferenciar los colores
        'rgba(75, 192, 192, 0.5)', // Cambiado para diferenciar los colores
        'rgba(54, 162, 235, 0.5)', // Cambiado para diferenciar los colores
        'rgba(255, 99, 132, 0.5)', // Cambiado para diferenciar los colores
      ],
      borderColor: [
        'rgba(255, 205, 86, 1)', // Cambiado para diferenciar los colores
        'rgba(75, 192, 192, 1)', // Cambiado para diferenciar los colores
        'rgba(54, 162, 235, 1)', // Cambiado para diferenciar los colores
        'rgba(255, 99, 132, 1)', // Cambiado para diferenciar los colores
      ],
      borderWidth: 1,
      cutout: '40%', // Cambiado para diferenciar los cortes
    },
  ],
};

const MultipleDoughnutChart = () => {
  return (
    <Card>
      <Card.Body>
        <Doughnut data={data} />
      </Card.Body>
    </Card>
  );
};

export default MultipleDoughnutChart;
