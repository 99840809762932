import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import "./charts.css";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const chartData = {
  labels: ['Santiago', 'Temuco', 'Talca', 'Sede 4'],
  datasets: [
    {
      label: 'Programas Acreditados',
      data: [120, 90, 60, 70], // Suponiendo cantidad de programas acreditados
      borderColor: "#3d3936",
      backgroundColor: "rgba(61, 57, 54, 0.2)",
      borderWidth: 1,
      pointBackgroundColor: '#3d3936',
    },
    {
        label: 'Programas no Acreditados',
        data: [85, 80, 90, 80], // Suponiendo tasas de graduación
        borderColor: "#e2211c",
        backgroundColor: "rgba(226, 33, 28, 0.2)",
        borderWidth: 1,
        pointBackgroundColor: '#e2211c',
      }
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  scales: {
    r: {
      angleLines: {
        display: true
      },
      suggestedMin: 0,
      suggestedMax: 150
    }
  }
};

function RadarChartExample() {
  return (
    <Card className="chart-back">
      <Card.Body>
        <Card.Title className="card-text">Programas Acreditados por Sede</Card.Title>
        <div style={{ height: '300px' }}>
          <Radar data={chartData} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
}

export default RadarChartExample;

