import React, { useState, useEffect } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row, Table, Alert } from "react-bootstrap";
import SubirCSV from "../SubirCSV";
import "./PantallaArchivos.css";
import systemvariablesManager from "../../hooks/systemvariablesManager";
import axios from 'axios'


const PantallaArchivos = () => { //aqui se trabaja
  const [Salida, SetSalida] = useState({
                                style: "",
                                text:"",
                                error: []
                              }
  );
  const [ Opcion, SetOpcion ] = useState("");
  
  const [ Listado, SetListado ] = useState([]);

  systemvariablesManager.GetTypeFileUpload(SetListado);


  const chartTypes = ['proyecto-facultad-sexo', 'historico-facultad', 'monto-ua', 'historico-proyecto-sede', 'historico-monto-sede','aportes-proyecto', 'financiamiento-interno', 'radarchart'];


  const handleUpdateAllClick = async () => {
    try {
        for (const type of chartTypes) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}charts/update-charts`, {
                typeDataUniversity: type
            });
            console.log(`Update successful for ${type}:`, response.data);
        }
        alert('All charts updated successfully!');
    } catch (error) {
        console.error('Failed to update charts:', error);
        alert('Failed to update charts');
    }
};
  
  console.log("OPCIOOON", Opcion)
  return <>
        <div className="d-flex flex-column border pt-4 align-items-center">
        <button 
  onClick={handleUpdateAllClick} 
  className="btn btn-primary btn-lg"
  style={{ margin: '10px', padding: '10px 20px', fontWeight: 'bold' }}>
  Update Charts
</button>

          <Card className="w-30 mb-5">
            <Card.Header>Seleccionar el tipo de archivo</Card.Header>
            <Card.Body>
              {Listado.map((obj, i) => {
                return <div className="form-check fs-5" key={"PantallaArchivos-item-"+i}>
                    <input className="form-check-input" type="radio" id={obj.label} value={obj.label} checked={obj.type === Opcion} onChange={() => SetOpcion(obj.type)}/>
                    <label className="form-check-label" htmlFor={obj.label} >{obj.label}</label>
                </div>
              })}
            </Card.Body>
          </Card>
          <SubirCSV Opcion={Opcion} SetSalida={(e) => {SetSalida(e)} } />
          {Salida.style != ""?
              <>
                <Alert variant={Salida.style} className="mb-5">
                  {Salida.text}
                </Alert>
                {Salida.error.length > 0? 
                  <Table bordered className="w-60 mb-4">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Error</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Salida.error.map((i, j) => {
                          return <tr key={"ErrorMessages-index-"+j}>
                                    <th scope="row">{j}</th>
                                    <td>{i}</td>
                                  </tr>
                        })}
                    </tbody>
                  </Table>
                  :
                  ""
                }
              </>
            :
              ""
          }
        </div>
    </>;
}

export default PantallaArchivos;