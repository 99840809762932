import React, { useState } from "react";
import "./DescargaPlanillas.css";
import formsManager from "../../hooks/formsManager";
import { Button, Table, Form } from "react-bootstrap";

//Selector de archivos
function DescargaPlanillas( props ) { //aqui se trabaja
    const [Marcas, setMarcas] = useState([]);

    // const DivSelect = (e, num) => {
    //     let checkbox = e.target.parentNode.childNodes[0].childNodes[0].childNodes[0];
    //     const temp = [...Marcas];
    //     checkbox.checked = !checkbox.checked;
    //     temp[num].selected = checkbox.checked;
    //     setMarcas(temp);
    // }


    const DivSelect = (num) => {
        CambiarData(num);
    }

    const CambiarData = (num) => {
        const temp = [...Marcas];
        temp[num].selected = !temp[num].selected;
        setMarcas(temp);
    }

    
    const MarcarTodas = () => {
        const temp = [...Marcas];
        for (let i = 0; i < temp.length; i++){
            temp[i].selected = true;
        }
        setMarcas(temp);
    }

    const DesmarcarTodas = () => {
        const temp = [...Marcas];
        for (let i = 0; i < temp.length; i++){
            temp[i].selected = false;
        }
        setMarcas(temp);
    }

    const DescargarArchivo = () => {
        Marcas.forEach((i) => {
            if (i.selected == true){
                formsManager.GetEspecificData(i._id, i.filename);
                //ObtenerDataYDescargar(i._id, i.filename);
            }
        });
    }

    formsManager.GetBase(setMarcas);

    return (<div className="d-flex flex-column border mb-2 align-items-center">
                <div className="d-flex flex-row mb-2 w-60 justify-content-evenly mt-4">
                    <Button variant="secondary" size="lg" onClick={() => {MarcarTodas()}}>Marcar todas</Button>
                    <Button variant="secondary" size="lg" onClick={() => {DesmarcarTodas()}}>Desmarcar todas</Button>
                </div>
                    <Table bordered className="w-60 mb-4">
                        <thead>
                            <tr>
                                <th className="w-5 fs-6" scope="col"></th>
                                <th scope="col">Nombre archivo</th>
                                <th scope="col">Tamaño</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Marcas.map((x, i) => {
                                // return <tr key={"DescargaPlanillas-item-"+i} onClick={(e) => {DivSelect(e, i)}}>
                                //     <th scope="row"><Form.Check className="fs-5" type="checkbox"/></th>
                                //     <td>{x.filename}</td>
                                //     <td>{x.filesize}</td>
                                return <tr key={"DescargaPlanillas-item-"+i}>
                                <th scope="row"><Form.Check className="fs-5" type="checkbox" checked={x.selected} onChange={() => {CambiarData(i)}}/></th>
                                <td onClick={() => {DivSelect(i)}}>{x.filename}</td>
                                <td onClick={() => {DivSelect(i)}}>{x.filesize}</td>


                                </tr>
                                })
                            }
                        </tbody>
                    </Table>
                <Button onClick={(e) => {DescargarArchivo(e)}} variant="primary" size="lg" className="w-30 mb-3 mt-4">Descargar</Button>
            </div>
    );
}

export default DescargaPlanillas;